import { useEffect, useState } from "react";
import Headline from "./Screen/Headline";

export default function Welcome({ config, showNext }) {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  useEffect(() => {}, []);

  return (
    <div className="screen screen-end">
      <Headline>
        Negotiation analysis
        <strong> Self-assessment Riemann-Thomann model</strong>
      </Headline>

      <div className="screen-end--inner">
        <div style={{ flex: 1 }}>
          <p>Welcome!</p>

          <p>The following assessment is part of your personal preparation and learning journey.</p>

          <p>
            We will assess your individual tendencies of the four dimensions of the so-called{" "}
            <strong>Riemann-Thomann model.*</strong>
          </p>

          <p>
            After answering all questions, you will receive a short evaluation based on your answers, which you can
            share with colleagues or friends .
          </p>
          <p>The purpose of this assessment is:</p>

          <ol>
            <li> to warm you up for your learning journey,</li>
            <li>to raise your awareness about concepts and ideas that might not be in your radar, and</li>
            <li>to help you focus on specific issues about analyzing human behavior.</li>
          </ol>

          <p>
            We encourage you to complete this assessment in one go, fully concentrated in giving your best answers. This
            will improve both your learning experience and your results.
          </p>

          <p>
            We trust that this assessment will stimulate good reflection and we look forward to discussing it with you
            during our joint sessions.
          </p>

          <p>
            <button onClick={showNext} className="inputs-button" style={{ margin: "25px 0" }}>
              Start
            </button>
          </p>

          <p>
            <strong>Riemann-Thomann model</strong>
          </p>
          <p>
            *The Riemann-Thomann model generally consists of four main behavioral dimensions: 1. continuity, 2.
            distance, 3. change, and 4. closeness.
          </p>
          <p>
            The Riemann-Thomann model can be applied as a helpful and practicable tool for individuals or negotiation
            teams to help them identify and understand differences, difficult situations or recurring conflicts.
            Furthermore, the model helps individuals and teams to be more aware of their potential needs behind their
            behaviour, in order to make better sense of situations that may cause them distress and identify conflict
            potential.
          </p>

          <hr />

          <p>
            &copy; 2022 - <a href={`mailto:${config.email}`}>{config.copyright}</a> |{" "}
            <a
              style={{ cursor: "pointer" }}
              onClick={event => {
                event.preventDefault();

                setShowPrivacyPolicy(!showPrivacyPolicy);
              }}
            >
              Privacy Policy
            </a>
          </p>

          <p style={{ display: showPrivacyPolicy ? "block" : "none" }}>
            <strong>Privacy Policy</strong>
          </p>

          <p style={{ display: showPrivacyPolicy ? "block" : "none" }}>
            The self-assessment tool on this website does not collect personal data for marketing purposes via cookies
            or other tracking technologies. After you have accessed the results page and closed it, your data will be
            deleted immediately.
          </p>
        </div>
      </div>
    </div>
  );
}

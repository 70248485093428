import { useEffect, useState } from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/Main";
import config from "./config";

import { defaultItems } from "./Utils";

export default function App() {
  const [current, setCurrent] = useState(-1);
  const [form, setForm] = useState({
    length: config.questions.length,
  });

  const getForm = () => {
    const { questions } = config;
    let total = 0.0;
    let summary = JSON.parse(JSON.stringify(config.summary));

    questions.map((question, index) => {
      if (question.scope === "buttons") {
        const answer = form?.[index];

        if (typeof answer !== "undefined") {
          const item = (question.items || defaultItems)[answer];
          const { weight } = item;

          if (typeof summary[question.dimension] === "undefined") {
            summary[question.dimension] = 0.0;
          }

          summary[question.dimension] += weight;
          total += weight;
        }

        delete form?.[index];
      }

      return false;
    });

    const data = {
      form,
      summary,
      total,
    };

    return data;
  };

  useEffect(() => {
    const url = new URL(window.location);
    const q = url.searchParams.get("q");

    if (q) {
      setCurrent(config.questions.length);
    }
  }, []);

  return (
    <>
      <Header />
      <Main
        current={current}
        setCurrent={setCurrent}
        config={config}
        form={form}
        setForm={setForm}
        getForm={getForm}
      />

      {current >= 0 && current < config.questions.length && (
        <Footer current={current} total={config.questions.length} />
      )}
    </>
  );
}

import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip } from "chart.js";
import { useEffect, useState } from "react";
import { Radar } from "react-chartjs-2";
import Headline from "./Screen/Headline";
import config from "../config";
import { shareText } from "../Utils";
import Share from "./Icons/Share";
import Printer from "./Icons/Printer";
import Brain from "./Icons/Brain";
import GaugeHigh from "./Icons/GaugeHigh";
import Aperture from "./Icons/Aperture";
import Arrows from "./Icons/Arrows";

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip);

const dimensions = Object.values(config.dimensions);
const data = {
  labels: dimensions,
  datasets: [
    {
      label: "",
      data: [0, 0, 0, 0],
      fill: true,
      backgroundColor: "rgba(3, 51, 78, 0.2)",
      borderColor: "rgb(3, 51, 78)",
      pointBackgroundColor: "rgb(3, 51, 78)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgb(3, 51, 78)",
    },
  ],
};

const options = {
  elements: {
    line: {
      borderWidth: 1,
    },
  },
  scales: {
    r: {
      angleLines: {
        display: true,
      },
      suggestedMin: 0,
      suggestedMax: 12,
      pointLabels: {
        font: {
          size: 14,
        },
      },
    },
  },
};

const shortenUrl = (url, callback) => {
  if (!url || url.length === 0) {
    return false;
  }

  fetch("https://api-ssl.bitly.com/v4/shorten", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.bitlyToken,
    },
    body: JSON.stringify({
      domain: "bit.ly",
      long_url: url,
    }),
  })
    .then(res => res.json())
    .then(res => {
      callback(res.link);
    });
};

export default function ScreenEnd({ getForm }) {
  var form = getForm();
  const { summary } = form;
  const [chartData, setChartData] = useState(data);
  const [shareUrl, setShareUrl] = useState(window.location.href);

  const parseUrl = () => {
    try {
      const url = new URL(window.location);
      const q = url.searchParams.get("q");

      return { q, url };
    } catch (e) {
      console.log("error", e);

      return { q: "", url: "" };
    }
  };

  useEffect(() => {
    let _chartData = [];

    Object.keys(summary).map(key => {
      _chartData.push(summary[key]);

      return false;
    });

    let values = Object.values(_chartData);
    const { q, url } = parseUrl();

    if (!q) {
      try {
        form = getForm();

        const base64 = btoa(
          JSON.stringify({
            ...form,
            values,
          })
        );

        url.searchParams.set("q", base64);
        window.history.pushState({}, "", url);

        setChartData({
          ...chartData,
          datasets: [
            {
              ...chartData.datasets[0],
              data: values,
            },
          ],
        });

        const { form: inputs } = form;

        shortenUrl(window.location.href, shortenUrl => {
          setShareUrl(shortenUrl);

          fetch(config.shareUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              form: {
                Vorname: inputs.firstname,
                Nachname: inputs.surname,
                "E-Mail Adresse": inputs.email,
              },
              summary: {
                [dimensions["0"]]: values["0"] || 0,
                [dimensions["1"]]: values["1"] || 0,
                [dimensions["2"]]: values["2"] || 0,
                [dimensions["3"]]: values["3"] || 0,
              },
              url: shortenUrl,
              apikey: "123",
            }),
          }).then(res => {
            console.log(res);
          });
        });
      } catch (e) {
        console.log("error", e);
      }
    } else {
      try {
        form = JSON.parse(atob(q || ""));

        shortenUrl(window.location.href, shortenUrl => {
          setShareUrl(shortenUrl);
        });

        setChartData({
          ...chartData,
          datasets: [
            {
              ...chartData.datasets[0],
              data: form.values,
            },
          ],
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const getShareText = () => {
    const { q } = parseUrl();

    try {
      form = JSON.parse(atob(q || ""));

      return shareText
        .replace(/{{firstname}}/im, form.form.firstname)
        .replace(/{{link}}/im, shareUrl || window.location.href);
    } catch (e) {
      return "";
    }
  };

  const shareButton = event => {
    event.preventDefault();

    navigator
      .share({
        title: "Negotiation analysis",
        text: getShareText(),
      })
      .then(() => {
        console.log("Thanks for sharing!");
      })
      .catch(console.error);
  };

  const printButton = event => {
    event.preventDefault();

    window.print();
  };

  return (
    <div className="screen screen-end">
      <Headline>
        <strong> Your result </strong>
      </Headline>

      <div className="screen-end--inner">
        <div style={{ flex: 1 }}>
          <p>
            The model is based on the assumption that all humans have some level of behavioral preference in them that
            can be categorized between the poles of two dimensions. The poles are continuity and change, as well as
            closeness and distance. The dimensions move between the pole of continuity and change, and closeness and
            distance, respectively, and can be visualized as shown in the graph. While each human has all these
            preferences within them, they tend to express them in different ways and at different levels.
          </p>

          <h3>Further explanation</h3>

          <p>
            <strong>
              <Brain /> What can a high value for continuity mean?
            </strong>
          </p>
          <p>
            The preference for continuity is related to a need for structure, for organization, for safety and the need
            to plan the future. It is also related to a need for reliability and trust in existing structures. Change
            tends to be particularly difficult for individuals with a strong need for continuity and can lead to
            feelings of insecurity.
          </p>

          <p>
            Strengths within this dimension are a focus on accuracy, persistence and the ability to create clear
            structures. When confronted with stressful situations, this behavioral tendency can lead to others
            perceiving inflexibility, stubbornness and a heightened attention to detail.
          </p>

          <p>
            <strong>
              <GaugeHigh /> What can a need for change mean?
            </strong>
          </p>
          <p>
            A preference for change is often related to a need for flexibility, a need for new challenges and for
            variability. It is often exemplified by working on many different projects at once, liking being 'in the
            middle of things', being spontaneous and at the center of activity in a group. Routines and fixed structures
            can be difficult to tolerate with these behavioral tendencies, potentially leading to feelings of boredom
            and being stuck.
          </p>

          <p>
            Strengths within this preference are a focus on innovative solutions, visionary ideas and an ability to deal
            with change flexibly and in a positive manner. When confronted with stressful situations, this behavioral
            tendency can lead to others perceiving what they consider to be chaotic behavior, careless decisions or
            superficial judgments.
          </p>

          <p>
            <strong>
              <Aperture /> What can a need for closeness mean?
            </strong>
          </p>
          <p>
            A preference for human closeness is related to a need for feeling attachment, others' company, being part of
            a community. This is a basic need for all human beings and is connected to our need to belong. It is also
            related to a need for harmony, trust and open communication in teams.
          </p>

          <p>
            Strengths within this preference are a focus on the quality of human connections within a group, a
            sensibility for the team spirit, and a strong willingness to help others.
          </p>
          <p>
            When confronted with stressful situations, this behavioral tendency can lead to others perceiving
            intrusiveness, sentimentality and unwanted advice.
          </p>

          <p>
            <strong>
              <Arrows /> What can a need for distance mean?
            </strong>
          </p>
          <p>
            A preference for distance, the ability to be reserved, is related to a need for independence, independence
            and freedom to make one's own decisions. Others tend to perceive this type of behavior as highly
            self-confident, decisive and solid.
          </p>

          <p>
            Strengths of this behavioral preferences are a focus on respecting individual differences, the importance of
            self-actualization and critical thinking. When confronted with stressful situations, this behavioral
            tendency can lead to others perceiving intolerance, as well as lack of sociability and concern for the
            group.
          </p>

          <p>
            <strong>What are the key takeaways?</strong>
          </p>
          <p>
            Negotiation teams are performing on the highest level when they represent a mixture, a diversity of all
            types of people, and so too, all behavioral preferences.
          </p>

          <p>
            The four basic orientations have an impact not only in the private sphere, but also in a deal situation, for
            example during important negotiations. In accordance with his or her basic tendencies, each person also has
            a suitable communication style.
          </p>

          <p>
            <strong>After all:</strong>
          </p>
          <p>
            The Riemann-Thomann model is simple with its restriction to four types, life is more colorful. Each model
            serves to reduce the complexity of reality until it is understandable.
          </p>

          <p>
            Finally, one could complain that the Riemann-Thomann model catalogs and draws people into boxes. On the one
            hand, this applies to every typology and, on the other hand, Mr. Thomann was not concerned with a typology
            of human characters, but with a vector field of modes of reaction in conflict. He did not primarily want to
            diagnose individual people, but to explain interpersonal polarizations and escalations and thus dissolve the
            hardening of the conflict, namely by making sure that one person is better able to see the behavior and
            motivation of the other as a reaction to himself. The Riemann-Thomann model is still used today in team
            development and conflict management.
          </p>

          <p>
            <strong>More information about the authors:</strong>
          </p>

          <p>
            Fritz Riemann
            <br />
            <a href="https://en.wikipedia.org/wiki/Fritz_Riemann_(psychologist)">
              https://en.wikipedia.org/wiki/Fritz_Riemann_(psychologist) (English)
            </a>
          </p>
          <p>
            Christoph Thomann
            <br />
            <a href="https://de.wikipedia.org/wiki/Christoph_Thomann">
              https://de.wikipedia.org/wiki/Christoph_Thomann (german)
            </a>
          </p>

          <p>
            {navigator.share && navigator.canShare ? (
              <button onClick={shareButton} className="inputs-button" style={{ width: "100%" }}>
                <Share /> Share your result
              </button>
            ) : (
              <a
                href={`mailto:?subject=${encodeURI("Negotiation analysis")}&body=${encodeURI(getShareText())}`}
                className="inputs-button"
                style={{ width: "100%" }}
              >
                <Share /> Share your result
              </a>
            )}

            <a onClick={printButton} className="inputs-button" style={{ width: "100%" }}>
              <Printer /> Print your result
            </a>
          </p>
        </div>

        <div className="screen-end--graph">
          <Radar data={chartData} width="350" options={options} />
        </div>
      </div>
    </div>
  );
}

import Logo from "./Icons/Logo";

export default function Header() {
  return (
    <header>
      <div className="container">
        <a href="/">
          <Logo />
        </a>
      </div>
    </header>
  );
}
